import React from 'react';
import {
  Added,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="May 2022" subnav="release-notes">
      <div id="May2022" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Happy May! The sun is out to stay, the flowers are blooming, and
          Uniform has some pretty sweet additions. Notably, the creatable select
          now features single-pill validation and editing, thanks to a
          contribution from the Supernova squad. Check out the rest of our
          updates below!
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.18.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases"
            />
            <div className={styles.changesList}>
              <Added>
                Single value validation and editing to{' '}
                <Link href="/components/forms/select/code#Creatable%20Select">
                  creatable multi-selects
                </Link>
                .
              </Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="4.5.0"
              versionUrl="https://github.com/hudl/jarvis/releases"
            />
            <div className={styles.changesList}>
              <Added>
                A new <code>OverflowShadow</code> component and{' '}
                <code>overflowShadow</code> color to be used in scrollable views
                with overflow.
              </Added>
              <Improved>
                Shadow styling for{' '}
                <Link href="/components/modules/histogram/design">
                  mobile histograms
                </Link>{' '}
                when zoom and pan is enabled.
              </Improved>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
